.topBar{
  padding-top: 80px;
}

@media (min-width: 640px) { 
  .topBar{
    padding-top: '0px';
  }
 }
@media (min-width: 768px) { 
  
 }
@media (min-width: 1024px) { 
  .topBar{
    padding-top: 112px;
  } 
 }
@media (min-width: 1280px) { 
  
 }
@media (min-width: 1536px) { 
  
 }